<template>
  <div class="containerFlexRow">
    <div class="containerFlexRow__left">
      <div class="containerFlexColumn">
        <ul class="wHP">
          <li v-for="nav in navList" :key="nav.id" class="nav" @click="link(nav)">
            <span class="iconfont fs30">{{ nav.icon | unescapeIcon }}</span>
            <span>{{ nav.menuName }}</span>
          </li>
        </ul>
        <div class="marginTopAuto marginBottom10">
          <el-popover v-model="visible" placement="right" width="250" trigger="manual">
            <div class="h300 flex directionColumn">
              <div class="hFP flex">
                <div class="flex" style="width: 25%">
                  <!--              <div class="h50" @click="openDialog">-->
                  <div class="h50" @click="openDialog(4)">
                    <el-avatar class="pointer" :size="50" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                  </div>
                </div>
                <div class="flex1">
                  <div class="flex" style="align-items: center">
                    <div class="fs20 blue pointer" @click="openDialog(1)">{{ user.nickName }}</div>
                    <i class="el-icon-lock marginLeftAuto pointer" @click="openDialog(2)"></i>
                  </div>
                  <div class="blue pointer" @click="openDialog(3)">{{ user.phonenumber }}</div>
                  <div>超级管理员</div>
                </div>
              </div>
              <div class="hFP userMenu">
                <el-menu default-active="2" class="el-menu-vertical-demo hFP" @open="handleOpen" @close="handleClose">
                  <el-menu-item index="1" disabled>
                    <span slot="title">消息中心</span>
                  </el-menu-item>
                  <el-menu-item index="2" @click="recharge">
                    <span slot="title">充值中心</span>
                  </el-menu-item>
                  <el-menu-item index="3" disabled>
                    <span slot="title">帮助中心</span>
                  </el-menu-item>
                  <el-menu-item index="4" @click="quitLogin">
                    <span slot="title">退出登陆</span>
                  </el-menu-item>
                </el-menu>
              </div>
            </div>
            <div slot="reference" class="pointer" @click="visible = !visible">
              <el-avatar icon="el-icon-user-solid" />
            </div>
          </el-popover>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :before-close="handleDialogClose">
          <userInfoSetting v-if="dialogVisible" :type="setType" @closedDialog="closedDialog" />
        </el-dialog>
      </div>
    </div>
    <div class="containerFlexRow__right box-shadow">
      <ul class="wHP">
        <li v-for="nav in children" :key="nav.id" class="nav" @click="link2(nav)">
          <span :class="{ selected: nav.selected }">{{ nav.menuName }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import userInfoSetting from "@/views/login/userInfoSetting.vue";
import { removeToken } from "@/utils/auth";
import { cloneDeep } from 'lodash';

export default {
  components: {
    userInfoSetting,
  },
  filters: {
    unescapeIcon(icon) {
      return unescape(icon.replace(/&#x/g, "%u").replace(/;/g, ""));
    },
  },
  watch: {
    $route(to, from) {
      this.currentPath = from.path;
    },
  },
  created() {
    this.currentPath = this.$route.path;
  },
  data() {
    return {
      navList: "",
      children: [],
      order: ["1"],
      dialogVisible: false,
      user: "",
      title: "",
      setType: 0,
      visible: false,
      currentPath: "",
    };
  },
  // name: "nav",
  mounted: function () {
    this.selectedNav(this.$route.path);
    this.showNav();
    this.getUserInfo();
  },
  methods: {
    quitLogin() {
      this.$confirm("确认退出登陆？")
        .then((_) => {
          removeToken();
          window.location.reload();
        })
        .catch((_) => {});
    },
    recharge() {
      window.open("http://h5.open.maiyatian.com/payment/rechargeForMerchant/?merchant_id=1454&sign=0A9CDEF1AB194EEC584344759FF670B5", "_blank");
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    closedDialog() {
      this.dialogVisible = false;
      this.getUserInfo();
    },
    openDialog(setType) {
      this.setType = setType;
      if (setType === 1) {
        this.title = "账号信息";
      } else if (setType === 2) {
        this.title = "修改密码";
      } else if (setType === 3) {
        this.title = "变更手机";
      } else if (setType === 4) {
        this.title = "更换头像";
      }
      this.dialogVisible = true;
    },
    getUserInfo() {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.user = userInfo;
    },
    handleDialogClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    showNav: function () {
      const nav = JSON.parse(JSON.stringify(store.getters.navs));
      this.navList = nav;
    },
    link: function (nav) {
      let url = nav.component
      if (url === '/order') {
        url = '/order/orderProcessing'
      } else if (url === '/call') {
        url = '/call/callDataCountUser'
      } else if (url === '/goods') {
        url = '/goods/goodsList'
      } else if (url === '/shop') {
        url = '/shop/shopList'
      } else if (url === '/store') {
        url = '/store/storeList'
      } else if (url === '/data') {
        url = '/data/tradeOverview'
      } else if (url === '/settings') {
        url = '/settings/merchantSetting'
      } else if (url === '/seller') {
        url = '/seller/sellerSort'
      } else if (url === '/delivery') {
        url = '/delivery/deliveryInfo'
      }
      if (this.$route.path !== nav.component) {
        this.$router.push({ path: nav.component });
        this.selectedNav(url);
      }
    },
    link2: function (nav) {
      let url = nav.component;
      let component = nav.component;
      if (component === "open") {
        window.open(nav.path);
        return;
      } else if (this.$route.path !== url) {
        this.$router.push({ path: url });
        this.selectedNav(url);
      }
    },
    selectedNav(path) {
      let url = path.slice(0, path.lastIndexOf("/"));
      let subUrl = this.$route.path;
      let navs = this.$store.getters.navs;
      navs.forEach((p) => {
        if (p.component == url) {
          this.children = p.children.filter((p) => p.visible === "0");
          this.children.forEach((sub) => {
            sub["selected"] = false;
            if (path != undefined && path != "" && path != null) {
              if (sub.component == path) {
                sub["selected"] = true;
              }
            } else if (sub.component == subUrl) {
              sub["selected"] = true;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.userMenu {
  .el-menu-item,
  .el-submenu__title {
    height: 40px;
    line-height: 40px;
  }
}
</style>

<style lang="scss" scoped>
div,
ul {
  border-right: 0;
  /*background-color: rgb(50, 64, 78);*/
}

.containerFlexRow {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: #f6f6f6;

  &__left {
    width: 60px;
  }
  &__right {
    background-color: white;
    flex: 1;
    margin: 10px;

    ul {
      height: 100%;
    }

    li {
      width: 100%;
      padding: 0 10px 10px 10px;
      text-align: left;
      height: 50px;
      line-height: 50px;
    }
  }
}

.containerFlexColumn {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 0;
  border: 1px solid rgb(222, 222, 222);
  display: flex;
  flex-direction: column;
  align-items: center;
  .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
}

.nav {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 5px 0;

  & > i {
    font-size: 35px;
  }

  &:hover {
    background-color: #5f9fd9;
    cursor: pointer;
  }
}

.selected {
  color: #409eff;
}
</style>
